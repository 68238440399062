import React, { useState } from "react"
import Page from "../components/Page"
import {
  contactPage,
  contactWrapper,
  title,
  button,
  buttonSelected,
  formWrapper,
  form,
  formField,
  submit,
  contactDetails,
  detailsTitle,
  disableButton,
  gdprWrapper,
  gdprList,
  snackbarStyleHide,
  snackbarStyleShow,
} from "../styles/contact.module.css"
import { init } from "emailjs-com"
import EmailService from "../services/EmailService"
import Layout from "../components/Layout"
import { useTranslation } from "react-i18next"
import { gaOptout } from "../services/googleAnalyticsHelper"
import { Link } from "gatsby"
import Seo from "../SEO"
import ReCAPTCHA from "react-google-recaptcha"

init(process.env.USER_ID)

const Contact = () => {
  const recaptchaRef = React.createRef()

  const [t] = useTranslation()

  const [type, setType] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const handleTypeChange = newType => {
    setType(newType)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    if (recaptchaValue === "") {
      showSnackBar(t("Solve Recaptcha"))
      return
    }
    var form = new FormData(document.getElementById("contact_form"))
    EmailService.send({
      name: form.get("name"),
      company: form.get("company"),
      comment: form.get("comment"),
      phone: form.get("phone"),
      email: form.get("email"),
      "g-recaptcha-response": recaptchaValue,
    }).then(() => {
      document.getElementById("comment").value = ""
      recaptchaRef.current.reset()
      showSnackBar(t("E-Mail-Sent"))
    })
  }

  const optOutOfGAAndShowDoneLabel = () => {
    gaOptout()
    setDisabled(true)
  }

  const showSnackBar = message => {
    var snackbar = document.getElementById("snackbar")
    snackbar.className = snackbarStyleShow
    snackbar.innerText = message

    setTimeout(function () {
      snackbar.className = snackbarStyleHide
    }, 3000)
  }

  return (
    <Page>
      <Seo
        title="Ihr Kontakt zu DeBaCode, Beratung von Expert:Innen"
        description="Unsere deutsprachigen Ansprechpartner stehen Ihnen zu jeder Zeit zur Seite und beraten Sie individuell"
        keywords={[
          "Beratung",
          "Qualität",
          "Digitalisierung",
          "Sozial",
          "IT-Management",
          "Entwicklung",
        ]}
      ></Seo>
      <Layout>
        <div className={contactPage}>
          <div className={contactWrapper}>
            <h1 className={title}>{t("Contact us")}</h1>
            <div>
              <button
                className={`${button} ` + (type === 0 && `${buttonSelected}`)}
                onClick={() => {
                  handleTypeChange(0)
                }}
              >
                {t("Business")}
              </button>
              <button
                className={`${button} ` + (type === 1 && `${buttonSelected}`)}
                onClick={() => {
                  handleTypeChange(1)
                }}
              >
                Student
              </button>
              <button
                className={`${button} ` + (type === 2 && `${buttonSelected}`)}
                onClick={() => {
                  handleTypeChange(2)
                }}
              >
                {t("Other")}
              </button>
            </div>
            <div className={formWrapper}>
              <form className={form} id="contact_form" onSubmit={handleSubmit}>
                <label htmlFor="name">{t("Name")}:</label>
                <input type="text" name="name" className={formField} required />
                <label htmlFor="company">
                  {type === 1
                    ? "Universität:"
                    : type === 2
                    ? "Organisation:"
                    : "Firma:"}
                </label>
                <input type="text" name="company" className={formField} />
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  name="email"
                  className={formField}
                  required
                />
                <label htmlFor="phone">Telefon:</label>
                <input type="tel" name="phone" className={formField} />
                <label htmlFor="comment">{t("Comment")}:</label>
                <textarea
                  type="text"
                  name="comment"
                  id="comment"
                  className={formField}
                  required
                />
                <button type="submit" className={submit} id="submit">
                  {t("Send")}
                </button>
              </form>
              <ReCAPTCHA
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
              />
            </div>
            <h1 className={title} id="impressum">
              {t("Imprint")}
            </h1>
            <ul className={contactDetails}>
              <h5 className={detailsTitle}>{t("Operator")}:</h5>
              <li key={1}>DeBaCode gemeinnützige UG (haftungsbeschränkt)</li>
              <li key={2}>Vogesenstraße 8</li>
              <li key={3}>76297 Stutensee</li>
              <h5 className={detailsTitle}>{t("LegalInformation")}:</h5>
              <li key={4}>{t("Management")}: Eric Wehner</li>
              <li key={5}>Amtsgericht Mannheim | HRB-Nr. 736205</li>
              <li key={6}>USt-ID: DE32 9699688</li>
              <h5 className={detailsTitle}>Telefon:</h5>
              <li key={7}>+49 (0) 156 78 343 545</li>
              <h5 className={detailsTitle}>Email:</h5>
              <li key={8}>kontakt@debacode.de</li>
              <h5 id="optout" className={detailsTitle}>
                Opt-Out-Cookie:
              </h5>
              <button
                className={disableButton}
                onClick={optOutOfGAAndShowDoneLabel}
              >
                {t("DisableGA")}
              </button>
              {disabled && <h3>Done!</h3>}
            </ul>
            <h1 className={title}>Datenschutzerklärung</h1>
            <div className={gdprWrapper}>
              <h2 id="datenschutz">Datenschutz</h2>
              <p>
                Wir haben diese Datenschutzerklärung (Fassung
                13.12.2020-111527480) verfasst, um Ihnen gemäß der Vorgaben der{" "}
                <a
                  href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=111527480"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutz-Grundverordnung (EU) 2016/679
                </a>{" "}
                zu erklären, welche Informationen wir sammeln, wie wir Daten
                verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher
                dieser Webseite haben.
              </p>
              <p>
                Leider liegt es in der Natur der Sache, dass diese Erklärungen
                sehr technisch klingen, wir haben uns bei der Erstellung jedoch
                bemüht die wichtigsten Dinge so einfach und klar wie möglich zu
                beschreiben.
              </p>
              <p>
                Verantwortliche für die Datenverarbeitung ist: <br />
                <br />
                Eric Wehner <br />
                <br />
                DeBaCode gemeinnützige UG (haftungsbeschränkt) <br />
                Vogesenstraße 8, 76297 Karlsruhe <br />
                kontakt@debacode.de
              </p>
              <h2>Allgemeine Informationen</h2>
              <p>
                Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer
                Person zu machen. Bei jedem Aufruf einer Webseite speichert der
                Webserver automatisch lediglich ein sogenanntes Server-Logfile,
                das z.B. den Namen der angeforderten Datei, Ihre IP-Adresse,
                Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den
                anfragenden Provider (Zugriffsdaten) enthält und den Abruf
                dokumentiert. Diese Zugriffsdaten werden ausschließlich zum
                Zwecke der Sicherstellung eines störungsfreien Betriebs der
                Seite sowie der Verbesserung unseres Angebots ausgewertet. Dies
                dient der Wahrung unserer im Rahmen einer Interessensabwägung
                überwiegenden berechtigten Interessen an einer korrekten
                Darstellung unseres Angebots gemäß Art. 6 Abs. 1 S. 1 lit. f
                DSGVO. Alle Zugriffsdaten werden spätestens sieben Tage nach
                Ende Ihres Seitenbesuchs gelöscht.
              </p>
              <h3>Hosting</h3>
              <p>
                Die Dienste zum Hosting und zur Darstellung der Webseite werden
                teilweise durch unsere Dienstleister im Rahmen einer
                Verarbeitung in unserem Auftrag erbracht. Soweit im Rahmen der
                vorliegenden Datenschutzerklärung nichts anderes erläutert wird,
                werden alle Zugriffsdaten sowie alle Daten, die in dafür
                vorgesehenen Formularen auf dieser Webseite erhoben werden, auf
                ihren Servern verarbeitet. Bei Fragen zu unseren Dienstleistern
                und der Grundlage unserer Zusammenarbeit mit ihnen wenden Sie
                sich bitte an die in dieser Datenschutzerklärung beschriebenen
                Kontaktmöglichkeit.
              </p>
              <h3>Datenverarbeitung zur Kontaktaufnahme</h3>
              <p>
                Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen
                einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
                E-Mail) freiwillig mitteilen. Pflichtfelder werden als solche
                gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur
                Bearbeitung Ihrer Kontaktaufnahme benötigen und Sie ohne deren
                Angabe die Kontaktaufnahme nicht versenden können. Welche Daten
                erhoben werden, ist aus den jeweiligen Eingabeformularen
                ersichtlich. Wir verwenden die von Ihnen mitgeteilten Daten zur
                Bearbeitung Ihrer Anfragen gemäß Art. 6 Abs. 1 S. 1 lit. b
                DSGVO. Weitere Informationen zu der Verarbeitung Ihrer Daten
                finden Sie in den nachfolgenden Abschnitten dieser
                Datenschutzerklärung. Nach vollständiger Abwicklung des
                Vorganges werden Ihre Daten für die weitere Verarbeitung
                eingeschränkt und nach Ablauf etwaiger steuer- und
                handelsrechtlichen Aufbewahrungsfristen gemäß Art. 6 Abs. 1 S. 1
                lit. c DSGVO gelöscht, sofern Sie nicht ausdrücklich in eine
                weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a
                DSGVO eingewilligt haben oder wir uns eine darüber hinausgehende
                Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über
                die wir Sie in dieser Erklärung informieren.
              </p>
              <h3>Werbung per E-Mail mit optionaler Newsletteranmeldung </h3>
              <p>
                Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die
                hierfür erforderlichen oder gesondert von Ihnen mitgeteilten
                Daten, um Ihnen regelmäßig unseren E-Mail-Newsletter aufgrund
                Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO
                zuzusenden. Die Abmeldung vom Newsletter ist jederzeit möglich
                und kann entweder durch eine Nachricht an die in dieser
                Datenschutzerklärung beschriebene Kontaktmöglichkeit oder über
                einen dafür vorgesehenen Link im Newsletter erfolgen.
              </p>
              <p>
                Nach Abmeldung löschen wir Ihre E-Mail-Adresse aus der
                Empfängerliste, soweit Sie nicht ausdrücklich in eine weitere
                Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber
                hinausgehende Datenverwendung vorbehalten, die gesetzlich
                erlaubt ist und über die wir Sie in dieser Erklärung
                informieren.
              </p>
              <p>
                Der Newsletter wird gegebenenfalls auch durch unsere
                Dienstleister im Rahmen einer Verarbeitung in unserem Auftrag
                versendet. Bei Fragen zu unseren Dienstleistern und der
                Grundlage unserer Zusammenarbeit mit ihnen wenden Sie sich bitte
                an die in dieser Datenschutzerklärung beschriebenen
                Kontaktmöglichkeit.
              </p>
              <h2>Cookies und weitere Technologien</h2>
              <p>
                Um den Besuch unserer Webseite attraktiv zu gestalten und die
                Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir auf
                verschiedenen Seiten Technologien einschließlich sogenannter
                Cookies. Cookies sind kleine Textdateien, die automatisch auf
                Ihrem Endgerät gespeichert werden. Einige der von uns
                verwendeten Cookies werden nach Ende der Browser-Sitzung, also
                nach Schließen Ihres Browsers, wieder gelöscht (sog.
                Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät
                und ermöglichen uns, Ihren Browser beim nächsten Besuch
                wiederzuerkennen (persistente Cookies). Wir verwenden solche
                Technologien, die für die Nutzung bestimmter Funktionen unserer
                Webseite (z. B. Warenkorbfunktion) zwingend erforderlich sind.
                Durch diese Technologien werden IP-Adresse, Zeitpunkt des
                Besuchs, Geräte- und Browser-Informationen sowie Informationen
                zu Ihrer Nutzung unserer Webseite (z. B. Informationen zum
                Inhalt des Warenkorbs) erhoben und verarbeitet. Dies dient im
                Rahmen einer Interessensabwägung überwiegenden berechtigten
                Interessen an einer optimierten Darstellung unseres Angebots
                gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
              </p>
              <p>
                Zudem verwenden wir Technologien zu Erfüllung der rechtlichen
                Verpflichtungen, denen wir unterliegen (z.B. um Einwilligungen
                in die Verarbeitung Ihrer personenbezogenen Daten nachweisen zu
                können) sowie zu Webanalyse und Online-Marketing. Weitere
                Informationen hierzu einschließlich der jeweiligen
                Rechtsgrundlage für die Datenverarbeitung finden Sie in den
                nachfolgenden Abschnitten dieser Datenschutzerklärung.
              </p>
              <p>
                Soweit Sie in die Verwendung der Technologien gemäß Art. 6 Abs.
                1 S. 1 lit. a DSGVO eingewilligt haben, können Sie Ihre
                Einwilligung jederzeit widerrufen durch eine Nachricht an die in
                der Datenschutzerklärung beschriebenen Kontaktmöglichkeit.
                Alternativ können Sie auch folgenden Link aufrufen:{" "}
                <Link to="/contact#optout">debacode.de/contact</Link>. Bei der
                Nichtannahme von Cookies kann die Funktionalität unserer
                Webseite eingeschränkt sein.
              </p>
              <h2 id="google-analytics-datenschutzerklaerung">
                Google Analytics Datenschutzerklärung
              </h2>
              <p>
                Wir verwenden auf unserer Website das Analyse-Tracking Tool
                Google Analytics (GA) des amerikanischen Unternehmens Google
                Inc. Für den europäischen Raum ist das Unternehmen Google
                Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
                für alle Google-Dienste verantwortlich. Google Analytics sammelt
                Daten über Ihre Handlungen auf unserer Website. Wenn Sie
                beispielsweise einen Link anklicken, wird diese Aktion in einem
                Cookie gespeichert und an Google Analytics versandt. Mithilfe
                der Berichte, die wir von Google Analytics erhalten, können wir
                unsere Website und unser Service besser an Ihre Wünsche
                anpassen. Im Folgenden gehen wir näher auf das Tracking Tool ein
                und informieren Sie vor allem darüber, welche Daten gespeichert
                werden und wie Sie das verhindern können.
              </p>
              <h3>Was ist Google Analytics?</h3>
              <p>
                Google Analytics ist ein Trackingtool, das der
                Datenverkehrsanalyse unserer Website dient. Damit Google
                Analytics funktioniert, wird ein Tracking-Code in den Code
                unserer Website eingebaut. Wenn Sie unsere Website besuchen,
                zeichnet dieser Code verschiedene Handlungen auf, die Sie auf
                unserer Website ausführen. Sobald Sie unsere Website verlassen,
                werden diese Daten an die Google-Analytics-Server gesendet und
                dort gespeichert.
              </p>
              <p>
                Google verarbeitet die Daten und wir bekommen Berichte über Ihr
                Userverhalten. Dabei kann es sich unter anderem um folgende
                Berichte handeln:
              </p>
              <ul className={gdprList}>
                <li key={9}>
                  Zielgruppenberichte: Über Zielgruppenberichte lernen wir
                  unsere User besser kennen und wissen genauer, wer sich für
                  unser Service interessiert.
                </li>
                <li key={10}>
                  Anzeigeberichte: Durch Anzeigeberichte können wir unsere
                  Onlinewerbung leichter analysieren und verbessern.
                </li>
                <li key={11}>
                  Akquisitionsberichte: Akquisitionsberichte geben uns
                  hilfreiche Informationen darüber, wie wir mehr Menschen für
                  unser Service begeistern können.
                </li>
                <li key={12}>
                  Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer
                  Website interagieren. Wir können nachvollziehen welchen Weg
                  Sie auf unserer Seite zurücklegen und welche Links Sie
                  anklicken.
                </li>
                <li key={13}>
                  Conversionsberichte: Conversion nennt man einen Vorgang, bei
                  dem Sie aufgrund einer Marketing-Botschaft eine gewünschte
                  Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen
                  Websitebesucher zu einem Käufer oder Newsletter-Abonnent
                  werden. Mithilfe dieser Berichte erfahren wir mehr darüber,
                  wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen
                  wir unsere Conversionrate steigern.
                </li>
                <li key={14}>
                  Echtzeitberichte: Hier erfahren wir immer sofort, was gerade
                  auf unserer Website passiert. Zum Beispiel sehen wir wie viele
                  User gerade diesen Text lesen.
                </li>
              </ul>
              <h3>
                Warum verwenden wir Google Analytics auf unserer Webseite?
              </h3>
              <p>
                Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
                bestmögliche Service bieten. Die Statistiken und Daten von
                Google Analytics helfen uns dieses Ziel zu erreichen.
              </p>
              <p>
                Die statistisch ausgewerteten Daten zeigen uns ein klares Bild
                von den Stärken und Schwächen unserer Website. Einerseits können
                wir unsere Seite so optimieren, dass sie von interessierten
                Menschen auf Google leichter gefunden wird. Andererseits helfen
                uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen
                somit sehr genau, was wir an unserer Website verbessern müssen,
                um Ihnen das bestmögliche Service zu bieten. Die Daten dienen
                uns auch, unsere Werbe- und Marketing-Maßnahmen individueller
                und kostengünstiger durchzuführen. Schließlich macht es nur
                Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen,
                die sich dafür interessieren.
              </p>
              <h3>Welche Daten werden von Google Analytics gespeichert?</h3>
              <p>
                Google Analytics erstellt mithilfe eines Tracking-Codes eine
                zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden
                ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie
                das nächste Mal unsere Seite besuchen, werden Sie als
                „wiederkehrender“ User erkannt. Alle gesammelten Daten werden
                gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt
                erst möglich pseudonyme Userprofile auszuwerten.
              </p>
              <p>
                Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden
                Ihre Interaktionen auf unserer Website gemessen. Interaktionen
                sind alle Arten von Handlungen, die Sie auf unserer Website
                ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein
                Google-Konto) nützen, können über Google Analytics generierte
                Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt
                keine Google Analytics-Daten weiter, außer wir als
                Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen,
                wenn es gesetzlich erforderlich ist.
              </p>
              <p>Folgende Cookies werden von Google Analytics verwendet:</p>
              <p>
                <strong>Name:</strong> _ga
                <br />
                <strong>Wert: </strong>
                2.1326744211.152111527480-5
                <br />
                <strong>Verwendungszweck:</strong> Standardmäßig verwendet
                analytics.js das Cookie _ga, um die User-ID zu speichern.
                Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.
                <br />
                <strong>Ablaufdatum:</strong> nach 2 Jahren
              </p>
              <p>
                <strong>Name:</strong> _gid
                <br />
                <strong>Wert: </strong>
                2.1687193234.152111527480-1
                <br />
                <strong>Verwendungszweck:</strong> Das Cookie dient auch zur
                Unterscheidung der Webseitenbesucher
                <br />
                <strong>Ablaufdatum:</strong> nach 24 Stunden
              </p>
              <p>
                <strong>Name:</strong> _gat_gtag_UA_property-id
                <br />
                <strong>Wert:</strong> 1<br />
                <strong>Verwendungszweck:</strong> Wird zum Senken der
                Anforderungsrate verwendet. Wenn Google Analytics über den
                Google Tag Manager bereitgestellt wird, erhält dieser Cookie den
                Namen _dc_gtm_property-id.
                <br />
                <strong>Ablaufdatum: </strong>nach 1 Minute
              </p>
              <p>
                <strong>Name:</strong> AMP_TOKEN
                <br />
                <strong>Wert:</strong> keine Angaben
                <br />
                <strong>Verwendungszweck:</strong> Das Cookie hat einen Token,
                mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden
                kann. Andere mögliche Werte weisen auf eine Abmeldung, eine
                Anfrage oder einen Fehler hin.
                <br />
                <strong>Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr
              </p>
              <p>
                <strong>Name:</strong> __utma
                <br />
                <strong>Wert: </strong>
                1564498958.1564498958.1564498958.1
                <br />
                <strong>Verwendungszweck:</strong> Mit diesem Cookie kann man
                Ihr Verhalten auf der Website verfolgen und die Leistung messen.
                Das Cookie wird jedes Mal aktualisiert, wenn Informationen an
                Google Analytics gesendet werden.
                <br />
                <strong>Ablaufdatum:</strong> nach 2 Jahren
              </p>
              <p>
                <strong>Name:</strong> __utmt
                <br />
                <strong>Wert:</strong> 1<br />
                <strong>Verwendungszweck:</strong> Das Cookie wird wie
                _gat_gtag_UA_property-id zum Drosseln der Anforderungsrate
                verwendet.
                <br />
                <strong>Ablaufdatum:</strong> nach 10 Minuten
              </p>
              <p>
                <strong>Name:</strong> __utmb
                <br />
                <strong>Wert: </strong>3.10.1564498958
                <br />
                <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet,
                um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert,
                wenn neue Daten bzw. Infos an Google Analytics gesendet werden.
                <br />
                <strong>Ablaufdatum:</strong> nach 30 Minuten
              </p>
              <p>
                <strong>Name:</strong> __utmc
                <br />
                <strong>Wert:</strong> 167421564
                <br />
                <strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet,
                um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei
                handelt es sich um ein Session-Cookie und wird nur solange
                gespeichert, bis Sie den Browser wieder schließen.
                <br />
                <strong>Ablaufdatum:</strong> Nach Schließung des Browsers
              </p>
              <p>
                <strong>Name:</strong> __utmz
                <br />
                <strong>Wert:</strong>{" "}
                m|utmccn=(referral)|utmcmd=referral|utmcct=/
                <br />
                <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um
                die Quelle des Besucheraufkommens auf unserer Website zu
                identifizieren. Das heißt, das Cookie speichert, von wo Sie auf
                unsere Website gekommen sind. Das kann eine andere Seite bzw.
                eine Werbeschaltung gewesen sein.
                <br />
                <strong>Ablaufdatum:</strong> nach 6 Monaten
              </p>
              <p>
                <strong>Name:</strong> __utmv
                <br />
                <strong>Wert:</strong> keine Angabe
                <br />
                <strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um
                benutzerdefinierte Userdaten zu speichern. Es wird immer
                aktualisiert, wenn Informationen an Google Analytics gesendet
                werden.
                <br />
                <strong>Ablaufdatum:</strong> nach 2 Jahren
              </p>
              <p>
                <strong>Anmerkung:</strong> Diese Aufzählung kann keinen
                Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer
                Cookies immer wieder auch verändert.
              </p>
              <p>
                Hier zeigen wir Ihnen einen Überblick über die wichtigsten
                Daten, die mit Google Analytics erhoben werden:
              </p>
              <p>
                <strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an.
                Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken.
                So bekommen wir Informationen, wo Sie auf unserer Seite
                „unterwegs“ sind.
              </p>
              <p>
                <strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet
                Google die Zeit, die Sie auf unserer Seite verbringen, ohne die
                Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die
                Sitzung automatisch.
              </p>
              <p>
                <strong>Absprungrate</strong> (engl. Bouncerate): Von einem
                Absprung ist die Rede, wenn Sie auf unserer Website nur eine
                Seite ansehen und dann unsere Website wieder verlassen.
              </p>
              <p>
                <strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website
                ein Konto erstellen bzw. eine Bestellung machen, erhebt Google
                Analytics diese Daten.
              </p>
              <p>
                <strong>IP-Adresse:</strong> Die IP-Adresse wird nur in
                gekürzter Form dargestellt, damit keine eindeutige Zuordnung
                möglich ist.
              </p>
              <p>
                <strong>Standort:</strong> Über die IP-Adresse kann das Land und
                Ihr ungefährer Standort bestimmt werden. Diesen Vorgang
                bezeichnet man auch als IP- Standortbestimmung.
              </p>
              <p>
                <strong>Technische Informationen:</strong> Zu den technischen
                Informationen zählen unter anderem Ihr Browsertyp, Ihr
                Internetanbieter oder Ihre Bildschirmauflösung.
              </p>
              <p>
                <strong>Herkunftsquelle:</strong> Google Analytics
                beziehungsweise uns interessiert natürlich auch über welche
                Website oder welche Werbung Sie auf unsere Seite gekommen sind.
              </p>
              <p>
                Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das
                Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite
                abspielen), das Teilen von Inhalten über Social Media oder das
                Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen
                Vollständigkeitsanspruch und dient nur zu einer allgemeinen
                Orientierung der Datenspeicherung durch Google Analytics.
              </p>
              <h3>Wie lange und wo werden die Daten gespeichert?</h3>
              <p>
                Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
                Server befinden sich in Amerika und folglich werden Ihre Daten
                meist auf amerikanischen Servern gespeichert. Hier können Sie
                genau nachlesen wo sich die Google-Rechenzentren befinden:{" "}
                <a
                  href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.google.com/about/datacenters/inside/locations/?hl=de
                </a>
              </p>
              <p>
                Ihre Daten werden auf verschiedenen physischen Datenträgern
                verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar
                sind und vor Manipulation besser geschützt sind. In jedem
                Google-Rechenzentrum gibt es entsprechende Notfallprogramme für
                Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt
                oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer
                Dienstunterbrechung bei Google dennoch gering.
              </p>
              <p>
                Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer
                Ihrer Userdaten von 26 Monaten eingestellt. Dann werden Ihre
                Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die
                Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen
                uns fünf Varianten zur Verfügung:
              </p>
              <ul className={gdprList}>
                <li key={15}>Löschung nach 14 Monaten</li>
                <li key={16}>Löschung nach 26 Monaten</li>
                <li key={17}>Löschung nach 38 Monaten</li>
                <li key={18}>Löschung nach 50 Monaten</li>
                <li key={19}>Keine automatische Löschung</li>
              </ul>
              <p>
                Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im
                Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre
                Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B.
                Cookies der DoubleClick-Domain) verknüpft sind.
                Berichtergebnisse basieren auf aggregierten Daten und werden
                unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind
                eine Zusammenschmelzung von Einzeldaten zu einer größeren
                Einheit.
              </p>
              <h3>
                Wie kann ich meine Daten löschen bzw. die Datenspeicherung
                verhindern?
              </h3>
              <p>
                Nach dem Datenschutzrecht der Europäischen Union haben Sie das
                Recht, Auskunft über Ihre Daten zu erhalten, sie zu
                aktualisieren, zu löschen oder einzuschränken. Mithilfe des
                Browser-Add-ons zur Deaktivierung von Google
                Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern
                Sie, dass Google Analytics Ihre Daten verwendet. Das
                Browser-Add-on können Sie unter{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://tools.google.com/dlpage/gaoptout?hl=de
                </a>{" "}
                runterladen und installieren. Beachten Sie bitte, dass durch
                dieses Add-on nur die Datenerhebung durch Google Analytics
                deaktiviert wird.
              </p>
              <p>
                Falls Sie grundsätzlich Cookies (unabhängig von Google
                Analytics) deaktivieren, löschen oder verwalten wollen, gibt es
                für jeden Browser eine eigene Anleitung:
              </p>
              <p>
                <a
                  href="https://support.google.com/chrome/answer/95647?tid=111527480"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                </a>
              </p>
              <p>
                <a
                  href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111527480"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Safari: Verwalten von Cookies und Websitedaten mit Safari
                </a>
              </p>
              <p>
                <a
                  href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111527480"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                  auf Ihrem Computer abgelegt haben
                </a>
              </p>
              <p>
                <a
                  href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111527480"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Internet Explorer: Löschen und Verwalten von Cookies
                </a>
              </p>
              <p>
                <a
                  href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111527480"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Microsoft Edge: Löschen und Verwalten von Cookies
                </a>
              </p>
              <p>
                Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy
                Shield Framework, wodurch der korrekte und sichere Datentransfer
                persönlicher Daten geregelt wird. Mehr Informationen dazu finden
                Sie auf{" "}
                <a
                  href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
                  target="_blank"
                  rel="follow noopener noreferrer"
                >
                  https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=111527480
                </a>
                . Wir hoffen wir konnten Ihnen die wichtigsten Informationen
                rund um die Datenverarbeitung von Google Analytics näherbringen.
                Wenn Sie mehr über den Tracking-Dienst erfahren wollen,
                empfehlen wir diese beiden Links:{" "}
                <a
                  href="http://www.google.com/analytics/terms/de.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.google.com/analytics/terms/de.html
                </a>{" "}
                und{" "}
                <a
                  href="https://support.google.com/analytics/answer/6004245?hl=de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/analytics/answer/6004245?hl=de
                </a>
                .
              </p>
              <h2 id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen">
                Google Analytics Berichte zu demografischen Merkmalen und
                Interessen
              </h2>
              <p>
                Wir haben in Google Analytics die Funktionen für Werbeberichte
                eingeschaltet. Die Berichte zu demografischen Merkmalen und
                Interessen enthalten Angaben zu Alter, Geschlecht und
                Interessen. Damit können wir uns – ohne diese Daten einzelnen
                Personen zuordnen zu können – ein besseres Bild von unseren
                Nutzern machen. Mehr über die Werbefunktionen erfahren Sie{" "}
                <a
                  href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad
                </a>
                .
              </p>
              <p>
                Sie können die Nutzung der Aktivitäten und Informationen Ihres
                Google Kontos unter “Einstellungen für Werbung” auf{" "}
                <a href="https://adssettings.google.com/authenticated">
                  https://adssettings.google.com/authenticated
                </a>{" "}
                per Checkbox beenden.
              </p>
              <h2>Social Media</h2>
              <h3>Social Plugins von Facebook, Instagram, LinkedIn</h3>
              <p>
                Auf unserer Webseite werden Social Buttons von sozialen
                Netzwerken verwendet. Diese sind lediglich als HTML-Links in die
                Seite eingebunden, so dass beim Aufruf unserer Webseite noch
                keine Verbindung mit den Servern des jeweiligen Anbieters
                hergestellt wird. Klicken Sie auf einen der Buttons, öffnet sich
                die Webseite des jeweiligen sozialen Netzwerks in einem neuen
                Fenster Ihres Browsers Dort können Sie z.B. den Like- oder
                Share-Button betätigen.
              </p>
              <h3>Unsere Onlinepräsenzen auf Facebook, Instagram</h3>
              <p>
                Soweit Sie hierzu Ihre Einwilligung nach Art. 6 Abs. 1 S. 1 lit.
                a DSGVO gegenüber dem jeweiligen Social Media Betreiber erteilt
                haben, werden bei Besuch unserer Onlinepräsenzen auf den in der
                oben genannten sozialen Medien Ihre Daten für Marktforschungs-
                und Werbezwecke automatisch erhoben und gespeichert, aus denen
                unter Verwendung von Pseudonymen Nutzungsprofile erstellt
                werden. Diese können verwendet werden, um z.B. Werbeanzeigen
                innerhalb und außerhalb der Plattformen zu schalten, die
                mutmaßlich Ihren Interessen entsprechen. Hierzu werden im
                Regelfall Cookies eingesetzt. Die detaillierten Informationen
                zur Verarbeitung und Nutzung der Daten durch den jeweiligen
                Social Media Betreiber sowie eine Kontaktmöglichkeit und Ihre
                diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz
                Ihrer Privatsphäre, entnehmen Sie bitte den unten verlinkten
                Datenschutzhinweisen der Anbieter. Sollten Sie diesbezüglich
                dennoch Hilfe benötigen, können Sie sich an uns wenden.
              </p>
              <p>
                <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>{" "}
                ist ein Angebot der Facebook Ireland Ltd., 4 Grand Canal Square,
                Dublin 2, Irland („Facebook Ireland“) Die durch Facebook Ireland
                automatisch erhobenen Informationen über Ihre Nutzung unserer
                Online-Präsenz auf Facebook werden in der Regel an einen Server
                der Facebook, Inc., 1601 Willow Road, Menlo Park, California
                94025, USA übertragen und dort gespeichert. Für die USA liegt
                kein Angemessenheitsbeschluss der Europäischen Kommission vor.
                Unsere Kooperation stützt sich auf Standarddatenschutzklauseln
                der Europäischen Kommission. Die Datenverarbeitung im Rahmen des
                Besuchs einer Facebook Fanpage erfolgt auf Grundlage einer
                Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26
                DSGVO. Weitere Informationen (Informationen zu Insights-Daten)
                finden Sie{" "}
                <a
                  href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hier
                </a>
                .
              </p>
              <p>
                <a
                  href="https://help.instagram.com/519522125107875"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>{" "}
                ist ein Angebot der Facebook Ireland Ltd., 4 Grand Canal Square,
                Dublin 2, Irland („Facebook Ireland“) Die durch Facebook Ireland
                automatisch erhobenen Informationen über Ihre Nutzung unserer
                Online-Präsenz auf Instagram werden in der Regel an einen Server
                der Facebook, Inc., 1601 Willow Road, Menlo Park, California
                94025, USA übertragen und dort gespeichert. Für die USA liegt
                kein Angemessenheitsbeschluss der Europäischen Kommission vor.
                Unsere Kooperation stützt sich auf Standarddatenschutzklauseln
                der Europäischen Kommission. Die Datenverarbeitung im Rahmen des
                Besuchs einer Instagram Fanpage erfolgt auf Grundlage einer
                Vereinbarung zwischen gemeinsam Verantwortlichen gemäß Art. 26
                DSGVO. Weitere Informationen (Informationen zu Insights-Daten)
                finden Sie{" "}
                <a
                  href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  hier
                </a>
              </p>
              <h2>Google ReCAPTCHA</h2>
              <p>
                GOOGLE RECAPTCHA Zum Zwecke des Schutzes vor Missbrauch unserer
                Web-Formulare sowie vor Spam durch automatisierte Software (sog.
                Bots) erhebt Google reCAPTCHA Daten (IP-Adresse, Zeitpunkt des
                Besuchs, Browser-Informationen sowie Informationen zu Ihrer
                Nutzung unserer Webseite) und führt mittels eines sog.
                JavaScript sowie Cookies eine Analyse Ihrer Nutzung unserer
                Webseite durch. Daneben werden andere, durch Google Dienste in
                Ihrem Browser gespeicherte Cookies ausgewertet. Ein Auslesen
                oder Speichern von personenbezogenen Daten aus den
                Eingabefeldern des jeweiligen Formulars findet nicht statt.
              </p>
              <p>
                Die Abfrage dient der Unterscheidung, ob die Eingabe durch einen
                Menschen oder missbräuchlich durch automatisierte, maschinelle
                Verarbeitung erfolgt. Die Abfrage schließt den Versand der
                IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA
                benötigter Daten an Google ein. Zu diesem Zweck wird Ihre
                Eingabe an Google übermittelt und dort weiter verwendet. Ihre
                IP-Adresse wird von Google jedoch innerhalb von Mitgliedstaaten
                der Europäischen Union oder in anderen Vertragsstaaten des
                Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.
                Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
                von Google in den USA übertragen und dort gekürzt. Im Auftrag
                des Betreibers dieser Website wird Google diese Informationen
                benutzen, um Ihre Nutzung dieses Dienstes auszuwerten. Die im
                Rahmen von reCaptcha von Ihrem Browser übermittelte IP-Adresse
                wird nicht mit anderen Daten von Google zusammengeführt. Für
                diese Daten gelten die abweichenden Datenschutzbestimmungen des
                Unternehmens Google. Weitere Informationen zu den
                Datenschutzrichtlinien von Google finden Sie unter:{" "}
                <a
                  href="https://policies.google.com/privacy?hl=de"
                  title="Google Datenschutzerklärung"
                  rel="follow noopener noreferrer"
                  target="_blank"
                >
                  https://policies.google.com/privacy?hl=de
                </a>
              </p>
              <p>
                Quelle: Erstellt mit dem Datenschutz Generator von{" "}
                <a
                  href="https://www.adsimple.at/seo-suchmaschinenoptimierung/"
                  title="AdSimple® Suchmaschinenoptimierung"
                  rel="follow noopener noreferrer"
                  target="_blank"
                >
                  AdSimple® SEO
                </a>{" "}
                in Kooperation mit{" "}
                <a
                  href="https://www.meinhaushalt.at/"
                  target="_blank"
                  rel="follow noopener noreferrer"
                >
                  meinhaushalt.at
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="snackbar" className={snackbarStyleHide} />
      </Layout>
    </Page>
  )
}

export default Contact
