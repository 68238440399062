// extracted by mini-css-extract-plugin
export var button = "contact-module--button--SEtjU";
export var buttonSelected = "contact-module--buttonSelected--hzpHi";
export var contactDetails = "contact-module--contactDetails--VyT9U";
export var contactPage = "contact-module--contactPage--fjWMt";
export var contactWrapper = "contact-module--contactWrapper--BLjKX";
export var detailsTitle = "contact-module--detailsTitle--vtq4C";
export var disableButton = "contact-module--disable-button--a1n5e";
export var fadein = "contact-module--fadein--0PS+q";
export var fadeout = "contact-module--fadeout--w8yiJ";
export var form = "contact-module--form--QAoFy";
export var formField = "contact-module--formField--MYF5R";
export var formWrapper = "contact-module--formWrapper--Drveb";
export var gdprList = "contact-module--gdpr-list--b0P1b";
export var gdprWrapper = "contact-module--gdpr-wrapper--tE2Hc";
export var snackbarStyleHide = "contact-module--snackbar-style-hide--maw0q";
export var snackbarStyleShow = "contact-module--snackbar-style-show--xEvEd";
export var submit = "contact-module--submit--6R-XP";
export var succesStyle = "contact-module--succesStyle--ei79v";
export var title = "contact-module--title--kShq8";