import emailjs from "emailjs-com"

class EmailService {
  static async send(data) {
    const serviceID = process.env.SERVICE_ID
    const templateID = process.env.TEMPLATE_ID
    const userID = process.env.USER_ID
    emailjs.send(serviceID, templateID, data, userID).then(
      function () {
        console.log("SUCCESS!")
      },
      function (error) {
        console.log("FAILED...", error)
      }
    )
  }
}

export default EmailService
